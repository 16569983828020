













import {
  computed,
  defineComponent,
} from '@vue/composition-api';

import { isTabletUpEffect } from '~/effects/matchMedia';
import { getValidCSSUnit } from '~/constants/helpers';
import { ISplitViewExpectedAttrs } from './types';
import { componentWhiteList } from './helpers';

export default defineComponent({
  name: 'SplitView',
  inheritAttrs: false,
  setup(_, context) {
    const tabletUpMatch = isTabletUpEffect();
    const {
      height,
      sections,
      reverseOrderTabletUp,
    } = context.attrs as ISplitViewExpectedAttrs;

    const components = computed(() => (sections ?? [])
      .filter(({ component }) => componentWhiteList.has(component))
      .slice(0, 2));

    return {
      containerStyles: computed(() => ({
        height: getValidCSSUnit(height),
        flexDirection: reverseOrderTabletUp && tabletUpMatch.value ? 'row-reverse' : '',
      })),
      components,
    };
  },
});
