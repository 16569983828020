//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCImage from '~/components/FCImage.vue';

export default {
  name: 'SplitView',
  components: {
    FCImage,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    imgLoading: {
      type: String,
      default: 'lazy',
    },
    imgUrl: {
      type: String,
      default: '',
    },
    imgWidth: {
      type: String,
      default: '',
    },
    imgHeight: {
      type: String,
      default: '',
    },
    imgSizes: {
      type: String,
      default: 'sm: 125vw, md:100vw, lg:75vw',
    },
    pictureResponsiveAlt: {
      type: String,
      default: '',
    },
    isReverseOrder: Boolean,
    ignoreMediaQueryFor: {
      type: String,
      default: '',
    },
    resizeEqually: {
      type: Boolean,
      default: false,
    },
    isMobileReverseOrder: {
      type: Boolean,
      default: false,
    },
    imgProvider: {
      type: String,
      default: 'fcProvider',
    },
  },
  computed: {
    isReplacingResponsiveImage() {
      return this.$slots.replaceResponsiveImage;
    },
    splitViewClass() {
      return this.ignoreMediaQueryFor
        ? `splitview__container--ignore-${this.ignoreMediaQueryFor}`
        : '';
    },
    splitViewResizeEqually() {
      return (part) => (this.resizeEqually ? `splitview__${part}--resize-equally` : '');
    },
  },
};
