var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"splitview__container",class:[
    _vm.splitViewClass,
    {
      'splitview__container-reverse': _vm.isReverseOrder,
      'splitview__container-reverse-mobile': _vm.isMobileReverseOrder,
    } ],attrs:{"id":_vm.id}},[(_vm.isReplacingResponsiveImage)?_c('div',{staticClass:"splitview__block",class:_vm.splitViewResizeEqually('block')},[_vm._t("replaceResponsiveImage")],2):_c('div',{staticClass:"splitview__block",class:_vm.splitViewResizeEqually('block')},[_c('div',{staticClass:"fc-flex splitview__image"},[_c('FCImage',{attrs:{"display-type":"cover","fc-provider":_vm.imgProvider,"rounded":"","nuxt-image-props":{
          src: _vm.imgUrl,
          alt: _vm.pictureResponsiveAlt,
          sizes: _vm.imgSizes,
          loading: _vm.imgLoading,
          width: _vm.imgWidth,
          height: _vm.imgHeight,
        }}})],1),_vm._v(" "),_c('div',{staticClass:"splitview__overlay"},[_vm._t("overlay")],2)]),_vm._v(" "),_c('div',{staticClass:"splitview__content",class:_vm.splitViewResizeEqually('content')},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }