import { render, staticRenderFns } from "./SplitView.vue?vue&type=template&id=63cc1e02&scoped=true&"
import script from "./SplitView.vue?vue&type=script&lang=js&"
export * from "./SplitView.vue?vue&type=script&lang=js&"
import style0 from "./SplitView.vue?vue&type=style&index=0&id=63cc1e02&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63cc1e02",
  null
  
)

export default component.exports